import { NS_BUSINESS, NS_HOME, NS_PRECHECK } from 'constants/i18n';
import createRoute from 'prerender/createRoute';

const { getStaticPaths, getStaticProps } = createRoute({
  path: '/landing/v3',
  translateNamespace: [NS_HOME, NS_PRECHECK, NS_BUSINESS],
  getData: ({ reviews }) => ({ reviews }),
  noIndex: true,
});

export { V3Page as default } from 'components/landings/V3Page';
export { getStaticPaths, getStaticProps };
